@use '../../../../styles/colors';
@use '../../../../styles/typography';

.wrap {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
}

.icon {
  flex: 0 0 12px;
  width: 12px;
  height: 12px;
  margin-top: 2px;

  > path {
    fill: colors.$secondary-500-base;
  }
}

.content {
  @extend .large-body-text;
  @extend .primary-light;
  margin: 0;
}
