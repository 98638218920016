@use '../../../../../../styles/variables';
@use '../../../../../../styles/colors';

.actionBtn {
  padding: variables.$padding-16 variables.$padding-12 !important;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: variables.$margin-12;

  .icon {
    path, g > path {
      fill: colors.$grey-warm-600;
    }
  }
}

.actionName {
  margin: 0;
}

.listItem {
  &:hover {
    background-color: colors.$grey-violet-200;
  }
}
