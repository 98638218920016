@use '../../../../styles/colors';
@use '../../../../styles/mixins';
@use '../../../../styles/variables';

.compWrap {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 34px;
  width: 34px;

  svg {
    path {
      transition: fill .4s ease;
      fill: colors.$secondary-500-base;
    }
  }

  @include mixins.mobile {
    width: 150px;
    height: 40px;
  }
  border-radius: 90px;
  padding: variables.$padding-4 variables.$padding-8 variables.$padding-4 variables.$padding-4;
  background-color: colors.$white;
  border: 1px solid colors.$grey-violet-300;
  cursor: pointer;

  &:hover {
    background-color: colors.$grey-violet-200;
  }

  &:active {
    background-color: colors.$grey-violet-300;
  }
}

.desktopQuickActions {
  display: none;
  @include mixins.mobile {
    display: flex;
    align-items: center;
    text-align: center;
  }

  .btnTitle {
    color: colors.$secondary-500-base;
  }

  .downIcon {
    margin-left: variables.$margin-12;
  }
}

.mobileQuickActions {
  display: block;
  @include mixins.mobile {
    display: none;
  }
}


.open {
  &.compWrap {
    transition: background-color .4s ease;

    .btnTitle {
      transition: color .4s ease;
      color: colors.$white;
    }

    background-color: colors.$secondary-500-base !important;

    svg {
      path {
        transition: fill .4s ease;
        fill: colors.$white;
      }
    }
  }
}

.icon {
  width: 20px;
  height: 20px;
  margin-left: variables.$margin-4;
}

// custom overlay style for ant design
.dropDownWrap {
  :global {
    .dropdown-overlay {
      position: fixed;
      background: colors.$white;
      border: 1px solid colors.$grey-violet-200;
      box-shadow: 0 0 16px -4px rgba(223, 224, 233, 0.6);
      border-radius: 8px;
    }
  }
}

