@use 'colors';
@use 'mixins';
@use 'variables';
@use 'typography';

.antd-overwrite {
  .ant-tabs {
    flex-grow: 1;
  }

  // TODO: Remove this styles when solve the following issue:
  // https://github.com/ant-design/ant-design/issues/41568
  .ant-tabs-centered .ant-tabs-nav {
    > .ant-tabs-nav-wrap {
      justify-content: initial;
      flex: initial;
      margin: 0 auto;
    }
  }

  .ant-tabs-nav .ant-tabs-tab {
    padding: variables.$padding-8;

    &:not(:first-child) {
      margin-left: variables.$margin-12;
    }
  }

  .ant-tabs-top > .ant-tabs-nav {
    margin-bottom: 0;
  }

  .ant-tabs-tab-btn {
    color: colors.$grey-warm-600;
    font-weight: 700;
    @extend .h5;
    @include mixins.heading(16px, 22px);
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: colors.$secondary-500-base !important;
    text-shadow: none;
  }

  .ant-tabs-ink-bar {
    background: colors.$secondary-500-base !important;
    height: 2px !important;
  }

  .ant-tabs-nav:before {
    display: none;
  }
}
