@use 'colors';
@use 'variables';
@use 'mixins';
@use 'typography';

.antd-overwrite {
  .ant-picker-date-panel, .ant-picker-month-panel, .ant-picker-year-panel, .ant-picker-header, .ant-picker-body, .ant-picker-footer {
    min-width: 100vw;
    .ant-picker-header-view {
      display: flex;
      align-items: center;
      justify-content: center;

      button {
        @extend .main-body-text;
        color: colors.$primary-500-base;
        &:hover {
          color: #1c2964;
        }
      }
    }
    @include mixins.mobile {
      min-width: 308px;
    }
  }

  .ant-picker-header {
    border-bottom: 2px solid colors.$grey-violet-200;
  }

  .ant-picker-content {
    width: 100% !important;
    @extend .main-body-text;

    th {
      color: colors.$grey-warm-500;
    }
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: colors.$secondary-500-base;
    border-radius: 8px;
  }

  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner:before {
    border: 2px solid colors.$secondary-500-base;
    border-radius: 8px;
  }

  .ant-picker-cell-in-view .ant-picker-cell-inner {
    color: colors.$grey-warm-500;
    border-radius: 8px;
  }

  .ant-picker-cell-range-hover-start::after,
  .ant-picker-cell-range-hover-end::after,
  .ant-picker-cell-range-hover::after {
    border: none !important;
    background: colors.$primary-100;
  }

  .ant-picker-cell-range-start .ant-picker-cell-inner {
    border-bottom-left-radius: 8px !important;
    border-top-left-radius: 8px !important;
  }

  .ant-picker-cell-range-end .ant-picker-cell-inner {
    border-bottom-right-radius: 8px !important;
    border-top-right-radius: 8px !important;
  }

  .ant-picker-range .ant-picker-active-bar {
    background: none;
  }

  .ant-picker-cell-in-view.ant-picker-cell-in-range::before, .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before, .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
    background: colors.$primary-100 !important;
  }

  .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after, .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after,
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before, .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before {
    background: colors.$primary-200 !important;
  }

  .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
    background: colors.$secondary-500-base;
    color: colors.$white;
  }

  .ant-picker {
    width: 100%;
    box-shadow: none !important;
    border-radius: 8px;
    border: 2px solid colors.$grey-violet-200 !important;
    background: colors.$grey-violet-050 !important;
    color: colors.$grey-warm-400;
    padding: variables.$padding-12 variables.$padding-16;
    @extend .main-body-text;

    // Needs !important to override .ant-form-item-has-success
    &:hover {
      border: 2px solid colors.$primary-300 !important;
      background: colors.$grey-violet-050 !important;
      color: colors.$grey-warm-400 !important;
    }

    &-input {
      input[disabled]:hover {
        background: colors.$grey-warm-100;
      }
    }

    &-focused {
      border: 2px solid colors.$primary-500-base !important;
      color: colors.$primary-500-base !important;
      box-shadow: none;
    }

    &-disabled {
      border: 2px solid colors.$grey-warm-100 !important;
      background: colors.$grey-warm-100 !important;
      color: colors.$grey-warm-200 !important;

      &:hover {
        border: 2px solid colors.$grey-warm-100 !important;
        background: colors.$grey-warm-100 !important;
        color: colors.$grey-warm-200 !important;
      }
    }
  }

  .ant-picker-small {
    padding: 9.5px 7px;
  }
}
