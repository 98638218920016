@use '../../../styles/colors';
@use '../../../styles/variables';
@use '../../../styles/mixins';

.simpleInputContainer {
  display: flex;
  align-items: center;
  background: colors.$white;
  flex-grow: 1;
  border-radius: 6px;

  & > div {
    flex-grow: 1;
    height: unset !important;
  }
}

.simpleInput {
  width: 100%;
  border: none;

  &:focus {
    outline: none;
  }
}

.searchIcon {
  width: 18px;
  height: 18px;
  line-height: normal;
  margin-right: variables.$margin-8;

  & > svg {
    vertical-align: top;
  }

  display: none;
  @include mixins.mobile {
    display: inline-flex;
  }
}

.dropdownWrap {
  padding: variables.$padding-20;
}

.dropdownTitle {
  padding: 0 variables.$padding-4;
  margin-bottom: variables.$margin-20;
  text-transform: uppercase;
}

.showAll {
  margin-top: variables.$margin-20;
  padding: variables.$padding-16 0;
  border-top: 1px solid colors.$grey-violet-200;

  & > a {
    display: flex;
    align-items: center;
    float: right;

    & > svg {
      margin-left: variables.$margin-8;
    }
  }
}
