@use '../../../styles/variables';

.noMore {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .noMoreImg {
    flex-shrink: 0;
    margin-top: variables.$margin-24;
    width: 80px;
    height: 70px;
  }

  .noMoreText {
    white-space: pre-wrap;
    text-align: center;
    margin-top: variables.$margin-8;
  }
}
