@use 'colors';
@use 'variables';
@use 'typography';

.antd-overwrite {
  .ant-modal-confirm {
    .ant-modal-body {
      padding: 0;
    }
  }

  .ant-modal-confirm-body-wrapper {
    .ant-modal-confirm-body {
      display: grid;
      grid-template-columns: min-content;
      grid-template-rows: min-content;
      grid-column-gap: 0px;
      grid-row-gap: 0px;
      & > svg {
        width: 36px;
        height: 36px;
        grid-area: 1 / 1 / 2 / 2;
        margin-right: variables.$margin-12;
      }
      .ant-modal-confirm-title {
        grid-area: 1 / 2 / 2 / 3;
        align-self: center;
        text-transform: uppercase;
        @extend .h5;
        color: colors.$grey-warm-600;
      }
      .ant-modal-confirm-content {
        grid-area: 2 / 2 / 3 / 3;;
        color: colors.$grey-warm-500;
      }
    }
    .ant-modal-confirm-btns {
      display: flex;
      width: 100%;
      flex-direction: row-reverse;
      justify-content: flex-end;
      padding-top: variables.$margin-12;
      border-top: 1px solid colors.$grey-violet-200;
      .ant-btn-primary {
        margin-left: 0 !important;
        margin-right: variables.$margin-8;
      }

      .ant-btn-default:hover {
        border: 1px solid colors.$secondary-500-base !important;
      }
    }
  }
}
