@use 'colors';
@use 'variables';
@use 'typography';

.antd-overwrite {
  .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 35px;
  }

  .ant-select {
    height: 39px;
    &-item-empty {
      padding: variables.$padding-2 variables.$padding-12;
    }

    &-selector {
      border-radius: 8px !important;
    }

    &-item-option-active {
      background: colors.$grey-violet-200!important;
    }

    &-item-option-selected {
      font-weight: normal !important;
      background: colors.$grey-violet-300 !important;
    }

    .ant-select-selection-placeholder {
      color: colors.$grey-warm-400;
    }

  }

  .ant-select-focused .ant-select-selector,
  .ant-select-selector:focus,
  .ant-select-selector:active,
  .ant-select-open .ant-select-selector {
    box-shadow: none !important;
  }

  .ant-select-sm {
    @extend .small-body-text;
  }

  .ant-select-md {
    @extend .main-body-text;
  }

  .ant-select-item {
    @extend .large-body-text;
  }

  .ant-select-lg {
    .ant-select-clear, .ant-select-arrow {
      width: 16px !important;
      height: 16px !important;

      svg {
        width: 16px !important;
        height: 16px !important;
      }

      right: variables.$margin-24;
    }

    .ant-select-selector {
      padding: 0 variables.$padding-32 !important;

      input {
        padding: 0 variables.$padding-20 !important;
      }
    }

    @extend .large-body-text;
  }
}
