@use '../../styles/colors';
@use '../../styles/variables';

.label {
  margin-bottom: variables.$margin-8;
}

.todaysNotifications, .olderNotifications {
  display: flex;
  flex-direction: column;
  gap: variables.$margin-8;

  .unreadBagde {
    position: absolute;
    right: -4px;
    top: calc(50% - 4px);
    background: red;
    min-width: 8px;
    min-height: 8px;
    text-align: center;
    border-radius: 50%;
    color: colors.$white;
    font-size: 10px;
  }
}

.todaysNotifications {
  margin-bottom: variables.$margin-28;
}

.skeleton {
  margin-top: variables.$margin-16;
}
