@use 'colors';
@use 'variables';

.antd-overwrite {
  .ant-card-body {
    padding: variables.$padding-20;
  }
  .ant-card-bordered {
    border: 1px solid colors.$primary-100;
  }
}
