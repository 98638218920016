@use '../../../../../styles/colors';
@use '../../../../../styles/variables';

.featureContainer {
  padding: variables.$padding-8;
  max-width: 250px;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 16px;

  > svg {
    path {
      fill: colors.$secondary-500-base;
    }
  }
}
