@use 'colors';
@use 'variables';
@use 'typography';

.antd-overwrite {
  .ant-input-group.ant-input-group-compact {
    display: flex;
  }

  .ant-input-group.ant-input-group-compact > *:not(:last-child) {
    margin-right: variables.$margin-8;
  }

  .ant-input-group > .ant-input:first-child, .ant-input-group-addon:first-child,
  .ant-input-group > .ant-input:last-child, .ant-input-group-addon:last-child,
  .ant-input-group > .ant-input:not(:first-child):not(:last-child) {
    border-radius: 8px;
  }

  .ant-input-group > .ant-input-affix-wrapper:first-child, .ant-input-group-addon:first-child,
  .ant-input-group > .ant-input-affix-wrapper:last-child, .ant-input-group-addon:last-child,
  .ant-input-group > .ant-input-affix-wrapper:not(:first-child):not(:last-child) {
    border-radius: 8px;
  }

  .ant-form {
    .ant-form-item {
      margin-bottom: 22px;

      &-control-input {
        min-height: 32px !important;
      }

      &-explain,
      &-extra {
        min-height: 22px;
        color: colors.$danger-400;
        transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1); // sync input color transition
      }

      //&-with-help {
      //  margin-bottom: 0;
      //}

      .ant-form-item-label {
        /**
         * This solves an issue that exists on firefox for form verticals.
         * Tested also on Chrome and safari
         */
        flex: unset;

        margin-bottom: variables.$margin-8 !important;

        & > label {
          @extend .medium-caps-bold;
          height: min-content !important;
          color: colors.$grey-warm-600;
          .ant-form-item-tooltip {
            width: 12px;
            height: 12px;
          }
        }

        > .ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
          display: none !important;
        }

        > .ant-form-item-required:not(.ant-form-item-required-mark-optional):after {
          display: inline-block;
          color: #ff4d4f;
          font-size: 14px;
          font-family: SimSun, sans-serif;
          line-height: 1;
          content: '*';
        }

        > .ant-form-item-required::after {
          visibility: visible;
        }
      }

      .ant-form-item-control {
        flex: unset;
      }
    }
  }
}
