@keyframes fadeIn {
  0% { 
    opacity: 0%;
  }
  99% { 
    opacity: 5%;
  }
  100% {
    opacity: 100%;
  }
}

@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes slideInFromBottom {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes slideAwayToBottom {
  0% {
    transform: translateY(0%);
    opacity: 100%;
  }
  50% {
    transform: translateY(0%);
    opacity: 50%;
  }
  100% {
    transform: translateY(100%);
    opacity: 0%;
  }
}

@-webkit-keyframes slideFromLeft {
  0% { transform: translateX(-50%); }
  100% { transform: translateX(0%); }
}

@keyframes slideFromLeft {
  0% { transform: translateX(-50%); }
  100% { transform: translateX(0%); }
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}