@use 'variables';
@use 'colors';

.no-button-styles {
  background: none !important;
  border: none;
  padding: 0;
  cursor: pointer;
}

.no-list-styles {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.no-margin.no-margin.no-margin.no-margin {
  margin: 0 !important;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.scroll-bar {
  padding-right: variables.$padding-2;

  &::-webkit-scrollbar {
    width: 5.38px;
  }

  &::-webkit-scrollbar-track {
    background: colors.$primary-100;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: colors.$primary-200;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: colors.$grey-warm-400;
  }
}
