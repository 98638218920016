@use 'variables';
@use 'colors';
@use 'typography';

.antd-overwrite {
  .ant-table-thead > tr > th,
  .ant-table-column-title {
    @extend .h6;
    @extend .uppercase;
    flex: unset;
    color: colors.$primary-200;
  }

  .ant-table-header {
    border-radius: 10px;
  }

  .ant-table-tbody > tr > td {
    border-bottom: 0;
    font-size: 14px;
    line-height: 18px;
    padding: 17px variables.$padding-24;
  }

  .ant-table, .ant-table > table {
    border-radius: 8px 8px 0 0;
  }

  .ant-table-thead > tr:first-child > th:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-left: 1px solid colors.$primary-100;
  }

  .ant-table-thead > tr:first-child > th:last-child,
  .ant-table-thead > tr:first-child > td {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-right: 1px solid colors.$primary-100;
  }

  .ant-table-thead > tr > th,
  .ant-table-thead > tr > td {
    background: colors.$primary-050;
    border-bottom: 1px solid colors.$primary-100;
    border-top: 1px solid colors.$primary-100;
  }

  .ant-table-thead > tr > .ant-table-column-has-sorters {
    padding: 0;
  }

  .ant-table-column-sorters {
    padding: 12.5px 24px !important;
    justify-content: unset;
  }

  .ant-table {
    background: transparent;
  }

  .ant-table table {
    border-collapse: separate;
    border-spacing: 0 8px;
    margin: -8px 0 -7px 0;
  }

  .ant-table-tbody .ant-table-row .ant-table-cell:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-left: 1px solid colors.$primary-100;
  }

  .ant-table-tbody .ant-table-row .ant-table-cell:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-right: 1px solid colors.$primary-100;
  }

  .ant-table-tbody .ant-table-row .ant-table-cell {
    background: colors.$white;
    border-bottom: 1px solid colors.$primary-100;
    border-top: 1px solid colors.$primary-100;
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: #fafafa;
  }

  .ant-table-middle .ant-table-row .ant-table-cell {
    padding: variables.$padding-12 variables.$padding-24 !important;
  }

  .ant-table-middle .ant-table-thead > tr > th {
    padding: variables.$padding-8 variables.$padding-24 !important;
  }
}
