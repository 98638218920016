@use '../../../styles/colors';
@use '../../../styles/variables';
@use '../../../styles/mixins';
@use '../../../styles/typography';

.layout {
  padding-bottom: variables.$footer-height;
  background-color: colors.$grey-violet-050;
}

.sider {
  z-index: 1;
  display: none;
  width: 0 !important;
  max-width: 0 !important;
  background-color: colors.$white !important;
  @include mixins.desktop-large {
    margin-top: variables.$header-height-desktop;
    border-right: 1px solid colors.$grey-violet-300;
    display: block;
    overflow: auto;
    position: fixed !important;
    left: 0;
    width: max-content !important;
    max-width: max-content !important;
    min-width: variables.$sider-width !important;
  }

  height: calc(100% - #{variables.$header-height-desktop} - #{variables.$footer-height});

  &.withBanner {
    margin-top: variables.$subscription-banner-height-desktop + variables.$header-height-desktop;
    height: calc(100% - #{variables.$header-height-desktop} - #{variables.$subscription-banner-height-desktop} - #{variables.$footer-height});
  }
}

.main {
  background: colors.$white !important;
  margin: variables.$header-height-mobile 0 0 0;
  height: calc(100vh - #{variables.$header-height-mobile} - #{variables.$footer-height});

  &.withBanner {
    margin-top: calc(#{variables.$subscription-banner-height-mobile} + #{variables.$header-height-mobile});
    height: calc(100vh - #{variables.$subscription-banner-height-mobile} - #{variables.$header-height-mobile} - #{variables.$footer-height});
  }

  @include mixins.desktop-large {
    margin-top: variables.$header-height-desktop;
    height: calc(100vh - #{variables.$header-height-desktop} - #{variables.$footer-height});
    min-height: calc(100vh - #{variables.$header-height-desktop} - #{variables.$footer-height}) !important;
    &.withBanner {
      margin-top: calc(#{variables.$subscription-banner-height-desktop} + #{variables.$header-height-desktop});
      min-height: calc(100vh - #{variables.$subscription-banner-height-desktop} - #{variables.$header-height-desktop} - #{variables.$footer-height}) !important;
    }

    margin-left: variables.$sider-width;
  }
}

.menuContainer {
  border-radius: 12px;
  background-color: colors.$white;
  padding: variables.$padding-16 variables.$padding-12 variables.$padding-12 variables.$padding-12;
}

.accountSuspended {
  li[class*="ant-menu-item"] {
    cursor: not-allowed;
  }
}

.submenuWithTag {
  div[class*="ant-menu-submenu-title"] {
    display: flex;
    align-items: center!important;
  }
}

.menuItemWithTag {
  display: flex;
  align-items: center;
  gap: 12px;
}
