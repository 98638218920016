@use '../styles/variables';
@use '../styles/colors';
@use '../styles/keyframes';
@use '../styles/mixins';

.drawerContent {
  div[class*='ant-drawer-content'] {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    bottom: 0;
    width: 100vw !important;
    max-height: calc(100vh - 70px);
    height: min-content;
  }
}

.closeIcon {
  width: 16px;
  height: 16px;

  path {
    fill: colors.$grey-warm-600;
  }
}

.modal {
  bottom: 0;
  left: 0;
  width: 100%;

  @include mixins.mobile {
    top: 40px;
    bottom: unset;
  }
}
