@use '../../../../styles/variables';
@use '../../../../styles/typography';

.contentWrap {
  padding: variables.$padding-20;
}

.menuItem {
  display: flex;
  align-items: center;
  gap: 4px;

  p {
    margin: 0;
  }

  &.firstItem {
    margin-bottom: variables.$margin-4;
  }

  .contactEmail {
    @extend .title;
    cursor: pointer;
  }
}

.itemIcon {
  width: 12px;
  height: 12px;
}
