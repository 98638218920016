@use '../../../../styles/colors';
@use '../../../../styles/variables';
@use '../../../../styles/mixins';

.wrapModal {
  margin-top: -5px;
}

.formFields {
  border-bottom: 1px solid colors.$grey-violet-200;
  margin-bottom: variables.$margin-12;
}

.nameField {
  @include mixins.desktop-medium {
    width: 48%;
  }
}

.calendarIcon {
  fill: colors.$grey-warm-400 !important;
  color: colors.$grey-warm-400 !important;
}

.positionTitleCreate {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.noTitleText {
  color: colors.$danger-400;
}

.createdTitleText {
  color: colors.$success-400;
}

.addedButton {
  cursor: default;
}

.dateFields {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: variables.$margin-16;
}

.noTitleText {
  color: colors.$danger-400;
}

.createdTitleText {
  color: colors.$success-400;
}

.addedButton {
  cursor: default;
}

.dateFields {
  & > div {
    flex: 0 0 calc(50% - 0.5em);
    display: inline-block;
  }

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include mixins.desktop-medium {
    flex-direction: row;
  }
}

.formTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.formSubtitle {
  color: colors.$grey-warm-400;
  margin-right: variables.$margin-32;
}

.wrapButton {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0px !important;
}

.wrapButtonsWithoutSteps {
  display: flex;
  flex-direction: row;
}

.cancelButton {
  margin-left: variables.$margin-12;
}

.spinnerCreating {
  margin-left: variables.$margin-4;
}

.inlineSwitch {
  display: flex;
  align-items: center;
  gap: variables.$margin-8;
  margin-bottom: variables.$margin-28;

  div[class*='ant-form-item-control-input'] {
    min-height: min-content;
  }
}

.skipBtn {
  padding: variables.$padding-8 variables.$padding-20 variables.$padding-8 0 !important;
}
