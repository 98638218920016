@use '../../../../../styles/variables';
@use '../../../../../styles/colors';

.form {
  div[class*='ant-row'] {
    margin: 0 0 variables.$margin-16 0 !important;
  }
}

.checkboxFormItem {
  margin-bottom: 0 !important;
  div[class*='ant-form-item-control-input'] {
    min-height: min-content !important;
  }
}

.dateFieldsWrap {
  div[class*='ant-form-item-label'] label {
    &:after {
      display: inline-block;
      color: #ff4d4f;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: '*';
    }
  }
}

.dateFormFields {
  display: flex;
  align-items: baseline;
  gap: 16px;

  div[class*='ant-form-item'] {
    margin: 0 !important;
  }

  div[class*='ant-select-selector'] {
    min-width: 110px;
  }
}

.multipleSelect {
  margin-top: variables.$margin-16;
}

.divider {
  margin: variables.$margin-16 0;
}

.checkboxWithTooltip {
  display: flex;
  align-items: center;
  column-gap: 4px;
}

.actionButtons {
  display: flex;
}

.descriptionField {
  margin-bottom: variables.$margin-28;
}

.submitButton {
  margin-right: variables.$margin-12;
}
