@use '../../../styles/colors';

.profileImageContainer {
  width: 100%;
  background: colors.$white;
  border-radius: 50%;
  margin-right: 0;
  display: flex;
  align-items: flex-start;
}

.active {
  border: 3px solid colors.$success-500 !important;
}

.onboarding {
  border: 3px solid colors.$grey-warm-500 !important;
}

.inactive {
  opacity: 40%;
  border: 3px solid colors.$danger-500 !important;
}

.pending {
  border: 3px solid colors.$warning-500 !important;
}

.deleted {
  border: 3px solid colors.$grey-warm-300 !important;
}

.profileImage {
  padding: 2px !important;
  border-radius: 50%;
  height: 100%;
  width: 100%;
  object-fit: cover;
  overflow: hidden;
}
