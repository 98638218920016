@use 'colors';
@use 'variables';
@use 'mixins';

.tabsWrapper {
  display: flex;
  align-items: center;
  padding: variables.$padding-12 0 variables.$padding-12 variables.$padding-16;
  background-color: colors.$white;
  border-bottom: 1px solid colors.$primary-100;
  position: sticky;
  top: variables.$tabs-top-mobile;
  z-index: 3;
  @include mixins.desktop-medium {
    padding: variables.$padding-8 variables.$padding-48;
    top: variables.$tabs-top-desktop;
  }
}
