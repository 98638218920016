@use 'colors';
@use 'typography';

.antd-overwrite {
  .ant-notification-notice {
    padding: 0 !important;
    border-radius: 4px;
    border: 1px solid colors.$grey-violet-300;

    &.success-notification {
      .ant-notification-notice-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: colors.$success-050;
      }
    }

    &.warning-notification {
      .ant-notification-notice-icon {
        display: flex;
        justify-content: center;
        background-color: colors.$warning-050;
      }
    }

    &.error-notification {
      .ant-notification-notice-icon {
        display: flex;
        justify-content: center;
        background-color: colors.$danger-050;
      }
    }

    &.info-notification {
      .ant-notification-notice-icon {
        display: flex;
        justify-content: center;
        background-color: colors.$info-050;
      }
    }

    .ant-notification-notice-btn {
      float: none;
      display: block;
      margin-left: 46px;
      margin-top: 0;
      margin-bottom: 16px;

      &__text {
        margin-left: 12px;
        color: colors.$secondary-500-base;
        @extend .medium-caps-bold;
      }
    }

    .ant-notification-notice-close {
      top: 8px;
      right: 16px;
    }

    .ant-notification-notice-message {
      margin-top: 12px;
      margin-left: 44px;

      &__text {
        margin-left: 12px;
        color: colors.$grey-warm-600;
      }
    }

    .ant-notification-notice-description {
      margin-left: 44px;
      margin-right: 30px;

      &__text {
        color: colors.$grey-warm-600;
        margin-left: 12px;
        padding-bottom: 16px;
        padding-top: 8px;
      }
    }

    .ant-notification-notice-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 0;
      padding: 8px;
      height: 100%;
      border-radius: 3px 0 0 3px;
      top: 0;
    }
  }
}
