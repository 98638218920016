@use '../../../styles/colors';
@use '../../../styles/mixins';
@use '../../../styles/variables';

.header {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 6;
  border-bottom: 1px solid colors.$grey-violet-300;
}

.compWrap {
  position: relative;
  background-color: colors.$white;
  z-index: 6;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logos {
  display: flex;
  align-items: center;
  gap: variables.$margin-16;
}

.mobile {
  height: variables.$header-height-mobile;
  padding: variables.$padding-20;

  @include mixins.desktop-medium {
    display: none;
  }
}

.onboardingMobile {
  height: 18px;
  padding: variables.$padding-20;

  @include mixins.desktop-medium {
    display: none;
  }
}

.desktop {
  height: variables.$header-height-desktop;
  display: none;
  @include mixins.desktop-medium {
    padding: variables.$padding-24 variables.$padding-20;

    display: flex;
    align-items: center;
  }
}

.onboardingDesktop {
  height: 73px;
  display: none;
  @include mixins.desktop-medium {
    padding: variables.$padding-24 variables.$padding-20;
    height: 25px;

    display: flex;
    align-items: center;
  }
}

.colleaguesSearchContainer {
  &:empty {
    display: none;
  }

  width: 100%;
  display: flex;
  justify-content: center;
  min-width: 190px;
  padding: 0 variables.$padding-16;
}

.logo {
  height: 24px;
  cursor: pointer;

  @include mixins.desktop-large {
    display: none;
  }
}

.logoDesktop {
  cursor: pointer;
  display: none;
  height: 32px;

  @include mixins.desktop-large {
    display: block;
  }
}

.actions {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  gap: variables.$margin-8;
  @include mixins.mobile {
    gap: variables.$margin-16;
  }
}
