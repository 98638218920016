@use 'colors';
@use 'typography';
@use 'variables';
@use 'mixins';

.antd-overwrite {
  .ant-modal-wrap {
    overflow: hidden;
    @include mixins.mobile {
      overflow: auto;
    }
  }

  &.ant-scrolling-effect {
    width: 100% !important;
    overflow: auto !important;
  }

  .ant-modal {
    h5 {
      @extend .h5;
      @extend .uppercase;
      color: colors.$grey-warm-600 !important;
      margin: -#{variables.$margin-4} 0 variables.$margin-20 0;
    }

    &-mask {
      background-color: rgba(colors.$primary-500-base, 0.30);
    }

    &-confirm-paragraph {
      width: max-content;
      display: contents;
    }

    &-content {
      border-radius: 8px;
      border: 1px solid colors.$grey-violet-300;
      background: #ffffff;
      padding: variables.$padding-20;
    }

    &-title {
      color: colors.$grey-warm-500;
      @extend .h5;
    }

    &-header {
      background: inherit;
      border-radius: 4px 4px 0 0;
      border: none;
      padding: 0;
    }

    &-body {
      padding: 0;
    }

    &-footer {
      border: none;
      padding: 0;
    }

    div[class*='ant-form-item']:last-of-type {
      margin-bottom: 0 !important;
    }
  }
}
