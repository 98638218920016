@use 'colors';
@use 'typography';
@use 'variables';

.ant-input-overrides {
  box-shadow: none !important;
  border-radius: 8px;
  border: 2px solid colors.$grey-violet-200 !important;
  background: colors.$grey-violet-050 !important;

  // Needs !important to override .ant-form-item-has-success
  &.ant-input-disabled {
    border: 1px solid colors.$grey-warm-100 !important;
    background: colors.$grey-warm-050 !important;
    color: colors.$grey-warm-200 !important;

    &:hover {
      border: 1px solid colors.$grey-warm-100 !important;
      background: colors.$grey-warm-050 !important;
      color: colors.$grey-warm-200 !important;
    }
  }

  // Needs !important to override .ant-form-item-has-success
  &:hover {
    border: 2px solid colors.$primary-300 !important;
    background: colors.$grey-violet-050 !important;
    color: colors.$grey-warm-400;
  }

  // Needs !important to override .ant-form-item-has-success
  &:focus {
    border: 2px solid colors.$primary-500-base !important;
    background: colors.$white !important;
    color: colors.$primary-500-base !important;
  }

  // Font & padding per input size
  padding: 18px variables.$padding-24;
  @extend .main-body-text;
  color: colors.$grey-warm-600;

  &.ant-input-sm {
    padding: variables.$padding-12 variables.$padding-16;
    @extend .small-body-text;
  }

  &.ant-input-md {
    padding: variables.$padding-20 variables.$padding-24;
    @extend .main-body-text;
  }

  &.ant-input-lg {
    padding: variables.$padding-20 variables.$padding-32;
    @extend .large-body-text;
  }
}

.ant-affix-wrapper-overrides {
  .ant-input.ant-input.ant-input {
    border: none !important;
    border-radius: 0 !important;
    padding: 0 !important;
    background: inherit !important;
    box-shadow: none !important;

    &:hover,
    &:active,
    &:focus {
      border: none !important;
      padding: 0 !important;
      background: inherit !important;
    }
  }

  box-shadow: none !important;

  border-radius: 8px;
  background: colors.$grey-violet-050 !important;
  color: colors.$grey-warm-400;

  &.ant-input-affix-wrapper-disabled {
    border: 1px solid colors.$grey-warm-100 !important;
    background: colors.$grey-warm-050 !important;
    color: colors.$grey-warm-200 !important;

    &:hover {
      border: 1px solid colors.$grey-warm-100 !important;
      background: colors.$grey-warm-050 !important;
      color: colors.$grey-warm-200 !important;
    }
  }

  // Needs !important to override .ant-form-item-has-success
  &:hover {
    border: 2px solid colors.$primary-300 !important;
    color: colors.$grey-warm-400 !important;
  }

  // Needs !important to override .ant-form-item-has-success
  &-focused {
    border: 2px solid colors.$primary-500-base !important;
    color: colors.$primary-500-base !important;
  }

  // Font & padding per input size
  &.ant-input-affix-wrapper-sm {
    padding: variables.$padding-12 variables.$padding-16;
    @extend .small-body-text;
  }

  &.ant-input-affix-wrapper-md {
    padding: variables.$padding-20 variables.$padding-24;
    @extend .main-body-text;
  }

  &.ant-input-affix-wrapper-lg {
    padding: variables.$padding-20 variables.$padding-32;
    @extend .large-body-text;
  }
}

.antd-overwrite {
  // Regular input
  .ant-input {
    @extend .ant-input-overrides;
  }

  .ant-input-number {
    @extend .ant-input-overrides;
  }

  .ant-form {
    // Error state
    .ant-form-item-has-error {
      .ant-input {
        color: colors.$danger-400;
        border: 2px solid colors.$danger-400 !important;

        &:hover {
          border: 2px solid colors.$danger-400 !important;
        }

        &:focus {
          color: colors.$primary-500-base;
        }
      }
    }
  }

  // Affix Input
  .ant-input-affix-wrapper {
    @extend .ant-affix-wrapper-overrides;
  }

  .ant-form {
    .ant-form-item-has-error {
      .ant-input-affix-wrapper {
        color: colors.$danger-400;
        border: 2px solid colors.$danger-400 !important;

        &:hover {
          border: 2px solid colors.$danger-400 !important;
        }

        &:focus {
          color: colors.$primary-500-base;
        }
      }
    }
  }

  // General form input styles
  .ant-form {
    // Has value
    .ant-form-item-has-success {
      .ant-input {
        border: 2px solid colors.$grey-violet-200;
        background: colors.$grey-violet-050;
        color: colors.$grey-warm-600;
      }
    }
  }
}

.searchInput {
  &.ant-input-affix-wrapper-sm {
    background: colors.$white !important;
    padding: 9px variables.$padding-12 !important;
    border: 1px solid colors.$primary-100 !important;
    border-radius: 4px;
    max-width: 141px;
    width: 100%;

    ::placeholder {
      color: colors.$grey-warm-600;
    }

    &:hover {
      border-width: 1px !important;
    }
  }
}