@use '../../../styles/colors';
@use '../../../styles/variables';

.colleagueInfoCardContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: variables.$padding-48;
  position: relative;

  .backIconWrap {
    position: absolute;
    left: 12px;
    top: 32px;
    z-index: 2;

    .backIcon {
      path {
        fill: colors.$secondary-500-base;
      }
    }
  }

  &.headerBg:before {
    z-index: 1;
    position: absolute;
    top: 0;
    width: 100%;
    height: 108px;
    content: "";
    border-radius: 8px 8px 0 0;
    background: colors.$primary-500-base;
  }

  > .colleagueAvatarWrap {
    z-index: 1;
  }

  margin-bottom: variables.$margin-16 !important;
}

.profileButton {
  border: 1px solid colors.$secondary-500-base !important;
}

.colleagueName {
  text-align: center;
  margin-top: variables.$padding-12;
  margin-bottom: variables.$padding-4;
}

.departmentTag {
  margin-bottom: variables.$margin-16;
}

.colleaguePositionTitle {
  color: colors.$grey-warm-400;
  margin-bottom: variables.$margin-4;
}

.colleagueAvatarWrap {
  width: 100px;
  height: 100px;
}

.hireDateContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  .hireDateTimespan {
    margin: 0;
    color: colors.$primary-200;
  }
}

.divider {
  margin: variables.$margin-8 0;
}

.colleagueInfoWrap {
  display: flex;
  flex-direction: column;
  gap: variables.$margin-8;
}

.directReports {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: variables.$margin-4;
}
