@use '../../../styles/colors';
@use '../../../styles/variables';
@use '../../../styles/mixins';
@use '../../../styles/typography';

.summaryWrap.inline {
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;

  .textWrap {
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
    column-gap: variables.$margin-8;
  }

  .name {
    @extend .h5;
  }

  .avatarWrap {
    margin-right: variables.$margin-8;
  }
}

.summaryWrap {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  @include mixins.desktop-medium {
    flex-direction: row;
    justify-content: start;
    flex-wrap: nowrap;
    align-items: center;
  }

  .avatarWrap {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    @include mixins.desktop-medium {
      margin-right: variables.$margin-16;
      flex-shrink: 0;
    }
    margin-right: 0;
  }

  .textWrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: left;
    @include mixins.desktop-medium {
      justify-content: start;
      align-items: start;
      text-align: center;
    }
  }

  .name {
    font-weight: 600;
    white-space: nowrap;
    width: 155px;
    overflow: hidden;
    text-overflow: ellipsis;
    @include mixins.desktop-medium {
      text-align: left;
    }
  }

  .subheading {
    @extend .main-body-text;
    line-height: 20px;
    color: colors.$primary-200;
    white-space: nowrap;
    width: 155px;
    overflow: hidden;
    text-overflow: ellipsis;
    @include mixins.desktop-medium {
      text-align: left;
    }
  }
}
