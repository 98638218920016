@use '../../../../styles/colors';

.wizardWrap {
  position: relative;
}

.steps {
  position: absolute;
  right: 32px;
  top: 3px;
  color: colors.$grey-warm-400;
}
