@use '../../../styles/colors';
@use '../../../styles/mixins';
@use '../../../styles/variables';

.header {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 6;
  border-bottom: 1px solid colors.$grey-violet-300;

  &.withBanner {
    top: variables.$subscription-banner-height-mobile;
    @include mixins.desktop-medium {
      top: variables.$subscription-banner-height-desktop
    }
  }
}

.compWrap {
  position: relative;
  background-color: colors.$white;
  z-index: 6;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logos {
  display: flex;
  align-items: center;
  gap: variables.$margin-16;
}

.mobile {
  height: variables.$header-height-mobile;
  padding: 0 variables.$padding-20;

  @include mixins.desktop-medium {
    display: none;
  }
}

.desktop {
  height: variables.$header-height-desktop;
  display: none;
  @include mixins.desktop-medium {
    padding: 0 variables.$padding-20;

    display: flex;
    align-items: center;
  }
}

.searchContainer {
  .mobile {
    display: none;
  }

  width: 100%;
  display: flex;
  justify-content: center;
  min-width: 190px;
  padding: 0;

  @include mixins.desktop-medium {
    padding: 0 variables.$padding-16;
  }

  .search {
    width: 90%;
    @include mixins.desktop-large {
      max-width: 768px;
      margin: 0;
      //float: right;
      width: 100%;
    }
  }
}

.searchBtn {
  .searchIcon {
    height: 24px;

    path {
      fill: colors.$grey-warm-300;
    }

    cursor: pointer;

    @include mixins.desktop-large {
      display: none;
    }
  }
}

.logo {
  height: 24px;
  cursor: pointer;

  @include mixins.desktop-large {
    display: none;
  }
}

.logoDesktop {
  cursor: pointer;
  display: none;
  height: 32px;

  @include mixins.desktop-large {
    display: block;
  }
}

.burgerLogo {
  width: 24px;
  height: 18px;
  min-width: 24px;
  min-height: 18px;

  &.burgerLogoDesktop {
    @include mixins.desktop-large {
      display: none;
    }
  }
}

.actions {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  gap: variables.$margin-8;
  @include mixins.mobile {
    gap: variables.$margin-16;
  }
}

// custom drawer css
.drawer {
  top: 0;

  display: block;
  @include mixins.desktop-medium {
    display: none;
  }
}

.openDrawer {
  top: variables.$header-height-mobile !important;
}
