@use '../../../styles/colors';
@use '../../../styles/mixins';
@use '../../../styles/variables';
@use '../../../styles/typography';

.compWrap {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 34px;
  width: 34px;
  @include mixins.mobile {
    width: 40px;
    height: 40px;
  }
  border-radius: 90px;
  padding: variables.$padding-4 variables.$padding-8 variables.$padding-4 variables.$padding-4;
  background-color: colors.$white;
  border: 1px solid colors.$grey-violet-300;
  cursor: pointer;

  &:hover {
    background-color: colors.$grey-violet-200;
  }

  &:active {
    background-color: colors.$grey-violet-300;
  }
}

div[class*='ant-dropdown-open'] {
  &.compWrap {
    transition: background-color .4s ease;

    background-color: colors.$secondary-500-base !important;

    svg {
      path {
        transition: fill .4s ease;
        fill: colors.$white;
      }
    }
  }
}

.questionMarkIcon {
  width: 20px;
  height: 20px;
  margin-left: variables.$margin-4;
}

.menuItem {
  display: flex;
  align-items: center;
  gap: 4px;

  p {
    margin: 0;
  }

  &.firstItem {
    margin-bottom: variables.$margin-4;
  }

  .contactEmail {
    @extend .title;
    cursor: pointer;
  }
}

.itemIcon {
  width: 12px;
  height: 12px;
}
