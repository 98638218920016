@use '../../../styles/keyframes';

.pageSpin {
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.spinnerIcon {
  width: 40px;
  height: 40px;
  -webkit-animation: spin .6s linear infinite; /* Safari */
  animation: spin .6s linear infinite;

  &.sm {
    width: 24px;
    height: 24px;
  }

  &.md {
    width: 40px;
    height: 40px;
  }

  &.lg {
    width: 52px;
    height: 52px;
  }
}
