@use '../../../../styles/colors';
@use '../../../../styles/mixins';
@use '../../../../styles/variables';

.accountSetupContainer {
  min-height: 100vh;
  @include mixins.desktop-xlarge {
    flex-basis: 100%;
    display: flex;
  }
}

.layoutContentWrap {
  background: conic-gradient(from 180deg, #FF2458, #1C2964 90deg, #FF2458);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: variables.$padding-60 variables.$padding-32;
  flex-basis: 100%;
  @include mixins.desktop-xlarge {
    padding: 0 variables.$padding-32;
  }
}

.layoutContent {
  width: 100%;
  max-width: 560px;
}

.featuresWrap {
  display: none;

  @include mixins.desktop-xlarge {
    padding: 0 40px;
    background: colors.$grey-violet-200;
    display: flex;
    flex-basis: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 46px;
    max-width: 740px;
    height: auto;
    text-align: center;

    h3 {
      white-space: break-spaces;

      > strong {
        color: colors.$secondary-500-base;
      }
    }
    p {
      font-weight: 400 !important;
    }
  }
}

.featuresContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
}
