@use '../../../styles/colors';
@use '../../../styles/mixins';
@use '../../../styles/variables';

.compWrap {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 34px;
  width: 34px;
  @include mixins.mobile {
    width: 40px;
    height: 40px;
  }
  border-radius: 90px;
  padding: variables.$padding-4 variables.$padding-8 variables.$padding-4 variables.$padding-4;
  background-color: colors.$white;
  border: 1px solid colors.$grey-violet-300;
  cursor: pointer;

  &:hover {
    background-color: colors.$grey-violet-200;
  }

  &:active {
    background-color: colors.$grey-violet-300;
  }

  &.open {
    background-color: colors.$secondary-500-base !important;

    .rocketIcon path {
      fill: colors.$white;
    }
  }
}

.rocketIcon {
  width: 20px;
  height: 20px;
  margin-left: variables.$margin-4;

  path {
    fill: colors.$secondary-500-base;
    transition: fill .4s ease;
  }
}

