@use 'colors';
@use 'variables';
@use 'typography';

@mixin ui-button-default(
  $background, $color, $border,
  $hover-background, $hover-color, $hover-border,
  $focus-background, $focus-color, $focus-box-shadow, $focus-border,
  $active-background, $active-color, $active-box-shadow, $active-border,
  $disabled-background, $disabled-color, $disabled-border) {
  border-radius: 8px;
  height: auto;
  border: $border;
  background: $background;
  color: $color;
  padding: variables.$padding-12 variables.$padding-32;
  font-family: var(--cera-pro), sans-serif;
  @extend .h5;

  & > svg {
    & path {
      fill: $color;
    }
  }

  &:not(:disabled):not(.ant-btn-disabled){
    &:hover {
      border: $hover-border;
    }
  }

  &:hover {
    background: $hover-background;
    color: $hover-color !important;
    border: $hover-border;
    & > svg {
      & path {
        fill: $hover-color;
      }
    }
  }

  &.ant-btn-icon-only {
    width: auto;
    & > svg {
      margin: auto 0 auto auto;
      width: 16px;
    }
    padding: variables.$padding-12;
  }

  &.ant-btn-xsm {
    &.ant-btn-icon-only {
      height: auto;
      border-radius: 8px;
      & > svg {
        width: 12px;
        height: 12px;
      }
      padding: variables.$padding-4;
    }
    padding: variables.$padding-4 variables.$padding-12;
    @extend .medium-caps-bold;
  }

  &.ant-btn-sm {
    & > span > svg {
      width: 12px;
      height: 12px;
    }
    &.ant-btn-icon-only {
      height: auto;
      border-radius: 8px;
      & > svg {
        width: 12px;
        height: 12px;
      }
      padding: variables.$padding-8;
    }
    padding: variables.$padding-8 variables.$padding-20;
    @extend .medium-caps-bold;
  }

  &.ant-btn-md {
    & > span > svg {
      width: 12px;
      height: 12px;
    }
    &.ant-btn-icon-only {
      height: auto;
      border-radius: 8px;
      & > svg {
        width: 12px;
        height: 12px;
      }
      padding: variables.$padding-12;
    }
    padding: variables.$padding-12 variables.$padding-32;
    @extend .medium-caps-bold;
  }

  &.ant-btn-lg {
    & > span > svg {
      width: 20px;
    }
    &.ant-btn-icon-only {
      & > svg {
        width: 20px;
      }
      padding: variables.$padding-16;
    }
    padding: variables.$padding-20 variables.$padding-40;
    @extend .h4;
  }

  &:focus, &:focus-visible {
    background: $focus-background;
    box-shadow: $focus-box-shadow;
    color: $focus-color !important;
    outline: none;
    border: $focus-border;
    & > svg {
      & path {
        fill: $focus-color;
      }
    }
  }
  &:active {
    background: $active-background;
    box-shadow: $active-box-shadow !important;
    color: $active-color;
    border: $active-border;
    & > svg {
      & path {
        fill: $active-color;
      }
    }
  }
  &[disabled] {
    background: $disabled-background !important;
    color: $disabled-color !important;
    border: $disabled-border !important;
    text-decoration: none !important;
    & > svg {
      & path {
        fill: $disabled-color !important;
      }
    }
  }
}

.ui-button {
  &.ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    text-decoration: none !important;

    & > svg {
      width: 12px;
      height: 12px;
      margin-right: 8px;
    }

    &.ant-btn-primary {
      @include ui-button-default(colors.$secondary-500-base, colors.$white, 1px solid colors.$secondary-500-base,
          colors.$secondary-400, colors.$white, 1px solid colors.$secondary-400,
          colors.$secondary-500-base, colors.$white, 0 0 0 3px colors.$secondary-050, 1px solid colors.$secondary-500-base,
          colors.$secondary-600, colors.$white, 0 0 0 3px colors.$secondary-100, 1px solid colors.$secondary-600,
          colors.$secondary-100, colors.$white, 1px solid colors.$secondary-100);
      text-shadow: none !important;
    }

    &.ant-btn-primary-success {
      @include ui-button-default(colors.$success-200, colors.$success-500, 1px solid colors.$success-200,
          colors.$success-200, colors.$success-500, 1px solid colors.$success-400,
          colors.$success-200, colors.$success-500, 0 0 0 3px colors.$success-050, 1px solid colors.$success-200,
          colors.$success-600, colors.$success-500, 0 0 0 3px colors.$success-100, 1px solid colors.$success-600,
          colors.$success-100, colors.$success-500, 1px solid colors.$success-100);
      text-shadow: none !important;
    }

    &.ant-btn-ghost {
      @include ui-button-default(colors.$white, colors.$secondary-500-base, 1px solid colors.$white,
          colors.$secondary-050, colors.$secondary-500-base, 1px solid colors.$secondary-050,
          colors.$secondary-200, colors.$secondary-500-base, none, 1px solid colors.$secondary-200,
          colors.$secondary-100, colors.$secondary-500-base, none, 1px solid colors.$secondary-100,
          colors.$white, colors.$secondary-200, 1px solid colors.$white);
    }

    &.ant-btn-link {
      @include ui-button-default(none, colors.$secondary-500-base, none,
          none, colors.$secondary-600, none,
          none, colors.$secondary-600, none, none,
          none, colors.$secondary-600, none, none,
          none, colors.$secondary-200, none);

      &:hover {
        text-decoration: underline;
      }
    }

    @include ui-button-default(colors.$white, colors.$secondary-500-base, 1px solid colors.$secondary-500-base,
        colors.$secondary-050, colors.$secondary-500-base, 1px solid colors.$secondary-500-base,
        colors.$secondary-500-base, colors.$white, 0 0 0 3px colors.$secondary-050, 1px solid colors.$secondary-600,
        colors.$secondary-100, colors.$secondary-500-base, 0 0 0 3px colors.$secondary-100, 1px solid colors.$secondary-600,
        colors.$white, colors.$secondary-200, 1px solid colors.$secondary-200);
  }
}
