@use 'colors';
@use 'variables';
@use 'typography';

.antd-overwrite {
  .ant-collapse {
    background-color: inherit;
  }

  .ant-collapse-item {
    border: 1px solid colors.$primary-100 !important;
    border-radius: 8px !important;
    margin-bottom: variables.$margin-16;
    background-color: colors.$white;
  }

  .ant-collapse-header {
    @extend .h5;
    color: colors.$grey-warm-600;
    padding: 0 0 variables.$padding-20 0 !important;
    margin: variables.$margin-20 variables.$margin-20 0 variables.$margin-20;

    .ant-collapse-header-text {
      @extend .h5;
      color: colors.$grey-warm-600;
    }
  }

  .ant-collapse-arrow {
    right: 0px !important;
    width: 16px;
    padding: 0 !important;
    transition: .24s !important;

    path {
      fill: colors.$grey-warm-600;
    }
  }

  .ant-collapse-content-box {
    padding: variables.$padding-20 !important;
  }

  .ant-collapse-item-active {
    .ant-collapse-header {
      border-bottom: 1px solid colors.$grey-violet-200;

      .ant-collapse-arrow {
        transform: rotate(180deg) !important;
      }
    }
  }
}
