@use '../../../styles/colors';
@use '../../../styles/mixins';
@use '../../../styles/variables';
@use '../../../styles/typography';

.sider {
  z-index: 1;
  display: none;
  width: 0 !important;
  max-width: 0 !important;
  background-color: colors.$white !important;
  @include mixins.desktop-large {
    margin-top: variables.$header-height-desktop;
    border-right: 1px solid colors.$grey-violet-300;
    display: block;
    overflow: auto;
    height: calc(100% - #{variables.$header-height-desktop});
    position: fixed !important;
    left: 0;
    width: max-content !important;
    max-width: max-content !important;
    min-width: variables.$sider-width !important;
  }
}

.main {
  background: white !important;
  margin-top: variables.$header-height-mobile;
  min-height: calc(100vh - #{variables.$header-height-mobile} - #{variables.$header-title-height-mobile} - #{variables.$bottom-height-mobile});
  height: calc(100vh - #{variables.$header-height-mobile} - #{variables.$header-title-height-mobile} - #{variables.$bottom-height-mobile});
  @include mixins.desktop-large {
    margin-top: variables.$header-height-desktop;
    min-height: calc(100vh - #{variables.$header-height-desktop}) !important;
    height: calc(100vh - #{variables.$header-height-desktop}) !important;
    margin-left: variables.$sider-width;
  }
}

.menuContainer {
  border-radius: 12px;
  background-color: colors.$white;
  padding: variables.$padding-16 variables.$padding-12 variables.$padding-12 variables.$padding-12;

  :global {
    .app-menu-container {
      height: 100%;
      border-right: 0;

      &.ant-menu {
        .ant-motion-collapse-leave {
          padding: 0 !important;
        }

        background-color: colors.$white;

        .ant-menu-submenu {
          background-color: colors.$white;
          border-radius: 4px;

          .ant-menu-sub {
            background-color: colors.$white;

            .ant-menu-item-only-child {
              border-radius: 4px;

              @include mixins.heading(14px, 18px);
            }
          }

          &:hover {
            background: colors.$grey-violet-100;
          }

          .ant-menu-submenu-title {
            @include mixins.menuTitle(colors.$grey-warm-500)
          }

          .ant-menu-item {
            align-items: center;
            gap: 4px;

            @include mixins.body-text(14px, 18px);

            > svg {
              margin-top: 2px;
              width: 10px;
              height: 10px;
            }

            padding: variables.$padding-8 variables.$padding-20 variables.$padding-8 39px !important;
          }

          .ant-menu-item-selected {
            background-color: colors.$grey-violet-300 !important;
          }
        }

        .ant-menu-submenu-selected {
          .ant-menu-submenu-title {
            @include mixins.menuTitle(colors.$secondary-500-base)
          }
        }

        .ant-menu-submenu-selected.ant-menu-submenu-open {
          padding-right: 2px;

          &:hover {
            background: colors.$grey-violet-300;
          }

          & > .ant-menu-sub {
            background-color: colors.$white !important;
          }

          .ant-menu-submenu-title {
            @include mixins.menuTitle(colors.$grey-warm-500)
          }
        }

        .ant-menu-submenu-open {
          background-color: colors.$white !important;
        }

        .ant-menu-item {
          background-color: colors.$white;
          margin-bottom: variables.$margin-4;
          padding: variables.$padding-12 variables.$padding-16 !important;
          display: flex;
          border-radius: 4px;
          flex-direction: row;
          align-items: flex-end;
          height: auto;
          color: colors.$grey-warm-500;

          @include mixins.heading(14px, 18px);
          &:after {
            content: none;
          }

          & > span {
            line-height: 14px;
          }

          &:hover {
            background-color: colors.$grey-violet-200 !important;
          }

          .ant-menu-item-icon {
            margin-right: variables.$margin-8;
            width: 16px !important;
            height: 16px !important;

            & path {
              fill: colors.$grey-warm-500;
            }
          }

          &.ant-menu-item-selected {
            background: colors.$grey-violet-300;
            color: colors.$secondary-500-base !important;

            @include mixins.heading(14px, 18px);
            .ant-menu-item-icon {
              & path {
                fill: colors.$secondary-500-base;
              }
            }
          }
        }
      }
    }
  }
}
