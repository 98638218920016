.icon-xsmall {
  width: 8px;
  height: 8px;
  min-width: 8px;
  min-height: 8px;
}

.icon-small {
  width: 12px;
  height: 12px;
  min-width: 12px;
  min-height: 12px;
}

.icon-medium {
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
}

.icon-large {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
}

.icon-xlarge {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
}
