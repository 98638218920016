@use '../../../styles/colors';
@use '../../../styles/mixins';

.mobile {
  display: block;
  @include mixins.mobile {
    display: none;
  }
}

.desktop {
  display: none;
  @include mixins.mobile {
    display: block;
  }
}

.closeIcon {
  width: 14px;
  height: 14px;

  path {
    fill: colors.$primary-200;
  }
}

.colleagueSummaryDrawerBody {
  margin: -24px -24px 0 -12px;
}

.drawerContent {
  div[class*='ant-drawer-content'] {
    border-radius: 8px;
    bottom: 0;
    height: calc(100vh - 100px) !important;
    width: 100vw !important;
    @include mixins.mobile {
      height: 100vh !important;
      width: min-content !important;
      min-width: 360px;
    }
  }
}
