@use '../../../../styles/colors';
@use '../../../../styles/variables';
@use '../../../../styles/mixins';

.wrapModal {
  margin-top: -5px;
}

.formFields {
  border-bottom: 1px solid colors.$grey-violet-200;
  margin-bottom: variables.$margin-12;
}

.nameField {
  @include mixins.desktop-medium {
    width: 48%;
  }
}

.emailField {
  margin-bottom: variables.$margin-28;
}

.fullNameFields {
  display: flex;

  & > div {
    flex: 0 0 calc(50% - 0.5em);
    display: inline-block;
  }

  justify-content: space-between;
  flex-direction: column;
  @include mixins.desktop-medium {
    flex-direction: row;
  }
}

.formTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.formSubtitle {
  color: colors.$grey-warm-400;
  margin-right: variables.$margin-32;
}

.wrapButton {
  display: flex;
  align-content: flex-end;
  margin-bottom: 0 !important;
}
