@use '../../../../styles/colors';
@use '../../../../styles/mixins';
@use '../../../../styles/variables';
@use '../../../../styles/typography';

.compWrap {
  position: fixed;
  bottom: 0;
  z-index: 6;
  width: 100%;
  box-shadow: 0 0 16px -4px rgba(131, 137, 169, 0.2);
  background: colors.$white;
  padding: variables.$padding-12 0;
  @include mixins.desktop-large {
    display: none;
  }
}

.fabButton {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  background-color: colors.$secondary-500-base;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);

  & > svg {
    width: 16px;
    height: 16px;

    & path {
      fill: colors.$white
    }
  }
}

// custom drawer css
.drawer {
  bottom: 0;
  display: block;
  @include mixins.desktop-large {
    display: none;
  }
}

.openSubmenu {
  bottom: variables.$bottom-height-mobile !important;
}

.divider {
  &:first-of-type {
    margin: 0 !important;
  }

  margin: variables.$margin-4 0 0 !important;
}
