@use 'variables';
@use 'colors';

.antd-overwrite {
  .ant-divider {
    margin: variables.$margin-16 0;
    color: colors.$grey-violet-200;
    border-top: 1px solid colors.$grey-violet-200;
  }
}
