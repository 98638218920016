@use '../../../../styles/variables';

.avatarWrap {
  margin-right: variables.$margin-8;
  width: 23px;
  height: 23px;
  min-width: 23px;
  min-height: 23px;
}

.avatar {
  border-width: 1px;
  padding: 1px;
}

.componentWrap {
  min-height: 100%;
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
