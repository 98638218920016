@use '../../../styles/colors';
@use '../../../styles/mixins';
@use '../../../styles/variables';

.notificationsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .heading {
    margin-bottom: 16px;
  }
}

.listWrap {
  height: min-content;
  max-height: calc(100vh - 276px);
  position: relative;
  overflow-y: scroll;
}

.list {
  height: 100%;
  overflow: scroll;
}

.notificationBtn {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 34px;
  width: 34px;
  @include mixins.mobile {
    width: 40px;
    height: 40px;
  }
  border-radius: 50%;
  padding: variables.$padding-4 !important;
  background-color: colors.$grey-violet-050;
  border: 1px solid colors.$grey-violet-300 !important;
  cursor: pointer;

  .bellIcon path {
    fill: colors.$secondary-500-base;
  }

  transition: background-color .4s ease;

  &:hover {
    background-color: colors.$grey-violet-200 !important;
  }

  &:active {
    background-color: colors.$grey-violet-300 !important;
  }

  &.open {
    background-color: colors.$secondary-500-base !important;

    .bellIcon path {
      fill: colors.$white;
    }
  }

  .notificationBagde {
    position: absolute;
    right: 0px;
    top: 0px;
    background: red;
    min-width: 10px;
    min-height: 10px;
    text-align: center;
    border-radius: 50%;
    color: colors.$white;
    font-size: 10px;
  }
}

.newTabIcon {
  width: 16px;
  height: 16px;
  position: absolute;
  right: 19px;
  top: 23px;
  cursor: pointer;

  path {
    fill: colors.$grey-warm-600;
  }
}

.notificationsWrap {
  position: absolute;
  transform: translate(-264px, 12px);
  z-index: 10;
  padding: variables.$padding-20;
  background-color: colors.$white;
  border: 1px solid colors.$primary-100;
  border-radius: 8px;
  width: 390px;
  height: min-content;
  max-height: calc(100vh - 200px);
  box-shadow: 0px 0px 12px rgba(120, 118, 128, 0.3);
}

.bellIcon {
  height: 20px;
  width: 20px;

  path {
    transition: fill .4s ease;
  }
}
