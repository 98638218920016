@use 'colors';
@use 'variables';
@use 'typography';

.antd-overwrite {
  .ant-select-lg {
    height: 64px !important;
  }
  .ant-select-multiple {
    .ant-select-selection-item {
      height: 27px;
      background-color: colors.$primary-100;
    }

    .ant-select-selector {
      padding: variables.$padding-4 variables.$padding-12;
      align-content: center;
    }

    .ant-select-selection-item {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 2px 0 0;
      border-radius: 4px;
    }

    .ant-select-selection-item-content {
      @extend .main-body-text ;
      color: colors.$grey-warm-700;
    }

    .anticon-close {
      margin-top: 2px;

      svg {
        //fill: @grey-warm-700;
      }
    }

    .ant-select-selection-placeholder {
      left: variables.$padding-12;
    }

    .ant-select-selection-search {
      margin: 0;
      height: 100%;

      .ant-select-selection-search-input {
        margin: 0;
        height: 27px;

        @extend .main-body-text;
      }
    }
  }
}
