@use '../../../styles/colors';
@use '../../../styles/variables';
@use '../../../styles/mixins';

.formWrap {
  margin-bottom: variables.$margin-12;

  div[class*='ant-divider-horizontal'] {
    margin: variables.$margin-24 0;
  }
}

.actionButtons {
  display: flex;
  margin-top: variables.$margin-12;
}

.availablesDays {
  margin: 0;
}

.submitButton {
  margin-right: variables.$margin-12;
}

.typeSelectFormItem {
  margin-bottom: 16px !important;
}

.dateFields {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  & > div {
    width: 100%;
    display: inline-block;
    @include mixins.mobile {
      flex: 0 0 calc(50% - 0.5em);
    }
  }

  flex-direction: column;
  @include mixins.mobile {
    flex-direction: row;
  }
}

.error {
  color: colors.$danger-400;
  padding-bottom: variables.$padding-24;
  margin: 0;
}
