@use '../../../styles/colors';
@use '../../../styles/variables';


.notificationCard {
  div[class*='ant-card-body'] {
    padding: 0;
    margin: 0;
    border-radius: 7px;

    &:hover {
      background-color: colors.$primary-050 !important;
    }
  }
}

.notificationWrap {
  margin: 0;
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: row;
  gap: variables.$margin-12;
  height: min-content;
  padding: variables.$padding-8 variables.$padding-16 !important;

  & > svg {
    width: 12px;
    height: 12px;

    path {
      fill: colors.$secondary-500-base;
    }
  }
}

.notificationImg {
  flex-shrink: 0;
  width: 35px;
  height: 35px;
}

.notificationRightHandSection {
  flex-grow: 1;

  display: flex;
  align-items: flex-start;
  flex-direction: column;
  position: relative;

  .notificationMessage {
    text-align: left;
    flex-wrap: wrap;
    margin: 0 variables.$margin-12 0 0;
  }

  .notificationTime {
    color: colors.$primary-300;
    margin: 0;
  }
}
