@use '../../../styles/colors';
@use '../../../styles/variables';

.actionButtons {
  display: flex;
}

.descriptionField {
  margin-bottom: variables.$margin-28;
}

.submitButton {
  margin-right: variables.$margin-12;
}
