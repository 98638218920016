@use '../../../styles/colors';
@use '../../../styles/mixins';
@use '../../../styles/variables';

.compWrap {
  display: flex;
  position: relative;
  align-items: center;
  border-radius: 90px;
  padding: variables.$padding-4 variables.$padding-8 variables.$padding-4 variables.$padding-4;
  background-color: colors.$grey-violet-050;
  border: 1px solid colors.$grey-violet-300;
  cursor: pointer;

  width: 34px;
  height: 34px;
  padding: 0;
  @include mixins.mobile {
    height: 40px;
    width: 70px;
    padding: variables.$padding-4 variables.$padding-8 variables.$padding-4 variables.$padding-4;
  }

  &:hover {
    background-color: colors.$grey-violet-200;
  }

  &:active {
    background-color: colors.$grey-violet-300;
  }
}

.arrow {
  display: none;
  @include mixins.mobile {
    display: block;
    margin-left: variables.$margin-8;
    & > svg {
      fill: colors.$secondary-500-base;
    }
  }
}

.dropdownWrap {
  :global {
    .dropdown-overlay {
      position: fixed;
      background: colors.$white;
      border: 1px solid colors.$grey-violet-200;
      box-shadow: 0 0 16px -4px rgba(223, 224, 233, 0.6);
      border-radius: 8px;
    }
  }
}
