@use '../../../styles/colors';

.icon {
  height: 16px;
  width: 16px;
}

.iconContainer {
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  cursor: pointer;
  align-items: center;
  justify-content: center;

  &:focus,
  &:active {
    outline: 0 solid transparent;
  }
}

.bgViolet100 {
  background-color: colors.$grey-violet-100;
}

.bgTertiary50 {
  background-color: colors.$tertiary-050;
}

.initials {
  color: colors.$tertiary-500-base;
  user-select: none;
}

.imageContainer {
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
}

.img {
  display: inline;
  margin: 0 auto;
  height: 32px;
  width: auto;
}
