@use '../../styles/variables';
@use '../../styles/colors';

.plusIcon {
  width: 8px;
  height: 8px;
  fill: colors.$success-500;
}

.newTag {
  margin-right: variables.$margin-12;
}

.compWrap {
  :global {
    .position-autocomplete:has(section) {
      .ant-select-item {
        &:last-child {
          background: colors.$success-050;
          color: colors.$success-500;
        }
      }
    }
  }
}
