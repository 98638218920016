@use '../../../styles/colors';

.iconContainer {
  height: 100%;
  width: 100%;
  background: colors.$white;
  border: 1px solid colors.$primary-100;
  border-radius: 8px;
  margin-right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  path, g {
    fill: colors.$secondary-500-base !important;
  }

  width: 13px;
  height: 13px;
}
