@use '../../../styles/colors';
@use '../../../styles/mixins';
@use '../../../styles/variables';

.authLayoutWrap {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;

  @include mixins.desktop-xlarge {
    justify-content: space-between;
    flex-direction: unset;
  }
}

.layoutInfoContainer {
  background: conic-gradient(from 180deg, #FF2458 , #1C2964 90deg, #FF2458);
  min-height: 100vh;
  flex-basis: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: variables.$padding-16;

  @include mixins.desktop-xlarge {
    padding: variables.$padding-32;
  }
}

.infoWrap {
  display: flex;

  span {
    display: none;
  }

  @include mixins.desktop-xlarge {
    display: flex;
    gap: 16px;
    flex-direction: column;

    span {
      display: block;
      color: colors.$white;

      &:last-of-type {
        font-weight: normal;
      }
    }
  }
}

.logo {
  width: 206px;
  height: 63px;
}

.layoutContentWrap {
  margin: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1126px;
  gap: 16px;

  @include mixins.desktop-xlarge {
    margin: variables.$margin-32 0;
    justify-content: space-between;
    flex-direction: row;
    gap: 64px;
  }
}

.layoutContent {
  width: 100%;
  max-width: 560px;
}

.layoutInfoHeader {
  background: colors.$white;
  box-shadow: 0 0 12px rgba(120, 118, 128, 0.3);
  height: 78px;
  display: flex;
  align-items: center;
  justify-content: center;

  @include mixins.desktop-xlarge {
    box-shadow: none;
    text-align: center;
    background: transparent;
    height: 134px;
  }
}

.layoutInfoHeaderLogo {
  width: 238px;
  height: 53px;
  margin: 0;
  @include mixins.desktop-xlarge {
    margin: variables.$margin-32 0;
  }
}

.layoutImgWrap {
  display: none;

  @include mixins.desktop-xlarge {
    background: white;
    display: flex;
    flex-basis: 50%;
    height: auto;
    max-width: 1200px;
    width: auto;
    position: relative;
    justify-content: flex-end;
  }

  .layoutImg {
    position: sticky;
    top: 0;
    height: 100vh;
    max-height: 100%;
    width: auto;
  }
}

.layoutImgInfoContainer {
  &.desktop {
    @include mixins.desktop-xlarge {
      position: absolute;
      left: 50%;
      top: 230px;
      transform: translateX(-50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 1;
    }
  }
}

.companyLogoWrap {
  margin-bottom: variables.$margin-20;
  width: 100px;
  height: 100px;
  @include mixins.desktop-xlarge {
    background: colors.$white;
    width: 128px;
    height: 128px;
    padding: variables.$padding-12;
    border-radius: 8px;
  }
}
