@use 'colors';
@use 'variables';
@use 'typography';
@use 'mixins';

.antd-overwrite {
  .ant-pagination-prev {
    margin-right: 0;
  }

  .ant-pagination,
  .mini {
    margin: variables.$margin-8 0 variables.$margin-48 0;
    display: flex;
    justify-content: center;

    &-prev,
    &-next {
      min-width: 36px !important;
      height: 38px !important;
      border-radius: 8px !important;
      border-color: colors.$grey-violet-100 !important;

      & > button {
        border-radius: 8px;
        border-color: colors.$grey-violet-100 !important;
        color: colors.$grey-warm-600;
      }

      & > button:hover {
        border-color: colors.$secondary-500-base !important;
        color: colors.$secondary-500-base;
      }
    }

    &-jump-prev,
    &-jump-next {
      background-color: colors.$white !important;
      min-width: 36px !important;
      height: 38px !important;
      border-radius: 8px;
      border-color: colors.$grey-violet-100;

      & > a {
        color: colors.$grey-warm-600;

        & > div > * {
          @include mixins.tablet {
            top: 0;
          }
          top: 6px !important;
        }
      }
    }

    &-disabled {
      & > button {
        background: colors.$grey-warm-050 !important;
        border-color: colors.$grey-warm-100 !important;
        color: colors.$grey-warm-300;
      }

      & > button:hover {
        border-color: colors.$grey-warm-100 !important;
        color: colors.$grey-warm-300;
      }
    }

    &-item {
      background-color: colors.$white !important;
      margin: 0 variables.$margin-4 !important;
      min-width: 36px !important;
      height: 38px !important;
      border-radius: 8px;
      border-color: colors.$grey-violet-100 !important;

      & > a {
        color: colors.$grey-warm-600;
        padding: variables.$padding-8;
      }

      &-container {
        @include mixins.tablet {
          margin-top: variables.$margin-8;
        }
      }

      &-link-icon {
        color: colors.$secondary-500-base;
      }

      &-ellipsis {
        color: colors.$grey-warm-600 !important;
      }

      &-active {
        border-color: colors.$secondary-500-base !important;

        & > a {
          color: colors.$secondary-500-base;
          padding: variables.$padding-8;
        }
      }

      &:hover {
        border-color: colors.$secondary-500-base !important;

        & > a {
          color: colors.$secondary-500-base;
          padding: variables.$padding-8;
        }
      }
    }
  }

  .ant-pagination-options {
    .ant-select {
      font-size: 16px !important;
      font-weight: bold !important;
      color: colors.$grey-warm-600;

      &-selector {
        height: auto;
        border-color: colors.$grey-violet-100 !important;
        border-radius: 8px !important;

        &:hover {
          border-color: colors.$secondary-500-base;
        }
      }

      &-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
        box-shadow: 0 0 0 2px rgba(colors.$secondary-500-base, 0.2);
      }

      &-selection-item {
        padding-top: 2px;
      }

      &-arrow {
        transition: all 0.3s;
        color: colors.$grey-warm-600;
      }

      &-item {
        @extend .main-body-text;
        color: colors.$grey-warm-600;
      }

      &-item-option-active {
        background: colors.$grey-violet-050;
      }

      &-item-option-selected {
        background: colors.$grey-violet-200;
        font-weight: 700;
      }

      &:hover {
        .ant-select-selection-item {
          color: colors.$secondary-500-base;
        }

        .ant-select-arrow {
          color: colors.$secondary-500-base;
        }
      }
    }
  }
}
