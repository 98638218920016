@use '../../../styles/colors';
@use '../../../styles/variables';

.contentField {
  margin-top: variables.$margin-16;
}

.dateField {
  margin-top: variables.$margin-16;
  margin-bottom: 0 !important;

  p {
    color: colors.$grey-warm-400;
    margin-bottom: variables.$margin-16;
    display: inline-block;
  }

  div[class*='ant-row ant-form-item'] {
    margin-bottom: 0 !important;
  }

}

.actionButtons {
  border-top: 1px solid colors.$grey-violet-200;
  padding-top: variables.$padding-12;
  margin-top: variables.$margin-12;
  display: flex;
  gap: 12px;
}

.inlineSwitch {
  margin-top: variables.$margin-16;
  display: flex;
  align-items: center;
  gap: 8px;

  div[class*='ant-form-item-control-input'] {
    min-height: min-content;
  }
}
