@use 'colors';
@use 'variables';
@use 'mixins';
@use 'typography';

.antd-overwrite {
  .ant-menu-light:not(.ant-menu-horizontal) .ant-menu-submenu-title:hover {
    background: none;
  }
  .side-menu {
    .ant-drawer-content-wrapper {
      width: 256px !important;
    }

    .ant-drawer-body {
      padding: 0 variables.$padding-8 !important;

      .menu-container {
        &.ant-menu-inline {
          border-right: 0;
        }
      }
    }
  }

  .app-menu-container {
    height: 100%;
    border-inline-end: 0 !important;

    &.ant-menu {
      .ant-motion-collapse-leave {
        padding: 0 !important;
      }

      background-color: colors.$white;
      border-right: 0;

      .ant-menu-title-content {
        @extend .medium-caps-bold;
      }

      .ant-menu-submenu {
        background-color: colors.$white;
        border-radius: 4px;

        .ant-menu-sub {
          background-color: colors.$white;

          .ant-menu-item-only-child {
            border-radius: 4px;
            display: block;
            @extend .medium-caps-bold;
          }
        }

        &:hover {
          background: colors.$grey-violet-100;
        }

        .ant-menu-submenu-title {
          @include mixins.menuTitle(colors.$grey-warm-500);
          margin: variables.$margin-4 0 0 0;
          width: 100%;

          & span {
            margin-inline-start: 0 !important;
          }
        }

        .ant-menu-item-only-child {
          .ant-menu-title-content {
            @extend .main-body-text;
          }

          &.ant-menu-item-selected {
            font-weight: 700 !important;
            @extend .medium-caps-bold;

            .ant-menu-title-content {
              font-weight: 700 !important;
              @extend .medium-caps-bold;
            }
          }
        }

        .ant-menu-item {
          align-items: center;
          gap: 4px;
          @extend .main-body-text;
          font-weight: normal!important;

          > svg {
            margin-top: 2px;
            width: 10px;
            height: 10px;
          }

          padding: variables.$padding-8 variables.$padding-20 variables.$padding-8 39px !important;
        }

        .ant-menu-item-selected {
          background-color: colors.$grey-violet-300 !important;
        }
      }

      .ant-menu-submenu-selected {
        .ant-menu-submenu-title {
          @include mixins.menuTitle(colors.$secondary-500-base);
        }
      }

      .ant-menu-submenu-selected.ant-menu-submenu-open {
        padding-right: 2px;

        &:hover {
          background: colors.$grey-violet-300;
        }

        & > .ant-menu-sub {
          background-color: colors.$white !important;
        }

        .ant-menu-submenu-title {
          @include mixins.menuTitle(colors.$grey-warm-500);
        }
      }

      .ant-menu-submenu-open {
        background-color: colors.$white !important;
      }

      .ant-menu-item {
        background-color: colors.$white;
        margin: variables.$margin-4 0;
        padding: variables.$padding-12 variables.$padding-16 !important;
        display: flex;
        border-radius: 4px;
        flex-direction: row;
        align-items: flex-start;
        height: auto;
        color: colors.$grey-warm-500!important;

        & span {
          margin-inline-start: 0 !important;
        }

        @extend .medium-caps-bold;

        &:after {
          content: none;
        }

        & > span {
          line-height: 14px;
          display: contents;
        }

        &:hover {
          background-color: colors.$grey-violet-200 !important;
        }

        .ant-menu-item-icon {
          margin-right: variables.$margin-8;
          width: 16px !important;
          height: 16px !important;

          & path {
            fill: colors.$grey-warm-500;
          }
        }

        &.ant-menu-item-selected {
          background: colors.$grey-violet-300;
          color: colors.$secondary-500-base !important;

          @extend .medium-caps-bold;
          font-weight: bold !important;

          .ant-menu-item-icon {
            & path {
              fill: colors.$secondary-500-base;
            }
          }
        }
      }
    }
  }
}
