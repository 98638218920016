@use '../../../styles/colors';
@use '../../../styles/mixins';
@use '../../../styles/variables';
@use '../../../styles/typography';
@use '../../../styles/keyframes';

$gap: 16px;

.wrap {
  animation-name: slideInFromTop;
  animation-duration: .3s, .2s;
  animation-delay: 0ms, .3s;
  animation-fill-mode: both;
  animation-timing-function: ease-in;
  animation-iteration-count: 1, 1;

  position: fixed;
  width: 100%;
  top: 0;
  z-index: 7;
  background-color: colors.$sup-purple-700;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: $gap;
  height: variables.$subscription-banner-height-mobile;

  @include mixins.desktop-medium {
    flex-direction: row;
    height: variables.$subscription-banner-height-desktop;
  }

  h5 {
    color: colors.$white;
    margin: 0;

    @extend .medium-caps-bold;
    @include mixins.desktop-medium {
      @include mixins.heading(16px, 22px);
    }
  }
}
