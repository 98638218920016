@use '../../../styles/colors.scss';
@use '../../../styles/mixins';
@use '../../../styles/variables';

.compWrap {
  background-color: colors.$grey-warm-050;
  border-right: none;
  margin: 0;
  padding: variables.$padding-4 0 !important;
}

.options {
  background-color: colors.$white;
  height: 58px;
  min-height: 58px;
  margin: 0 !important;
  @include mixins.desktop-medium {
    height: 42px;
    min-height: 42px;
    padding: variables.$padding-12 variables.$padding-16;
  }

  &:hover {
    background-color: colors.$grey-violet-200 !important;
  }

  &:active {
    background-color: colors.$grey-violet-300 !important;
  }
}

.item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: variables.$padding-8 0;
  @include mixins.desktop-medium {
    padding: 0;
  }
}

.icon {
  width: 16px;
  height: 16px;

  path {
    fill: colors.$grey-warm-600;
  }

  margin-right: variables.$margin-12;
}

.text {
  color: colors.$grey-warm-600;
}
