@use 'colors';
@use 'alert';
@use 'typography';
@use 'reusable';
@use 'common';
@use 'inputs';
@use 'datepicker';
@use 'checkboxes';
@use 'radios';
@use 'table';
@use 'switches';
@use 'notifications';
@use 'collapse';
@use 'modal';
@use 'tabs';
@use 'pagination';
@use 'buttons';
@use 'dropdown';
@use 'card';
@use 'form';
@use 'select';
@use 'timeline';
@use 'confirm';
@use 'divider';
@use 'steps';
@use 'drawer';
@use 'uploader';
@use 'icon';
@use 'multi-select';
@use 'menu';

a {
  color: colors.$secondary-500-base;
  @extend .h5;
  -webkit-tap-highlight-color: transparent;
  &:hover {
    color: colors.$secondary-600;
    text-decoration: underline;
  }
}

html, body {
  overscroll-behavior: none;
}

body.no-scroll {
  height: 100vh;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: var(--source-sans-pro), serif, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell,
    Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: colors.$grey-warm-600;
  background-color: colors.$grey-violet-050;
  -webkit-tap-highlight-color: transparent;
}

main {
  background-color: colors.$grey-violet-050;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  -webkit-tap-highlight-color: transparent;
}

p {
  margin-top: 0;
  margin-bottom: 1em;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

button {
  -webkit-tap-highlight-color: transparent;
}

#progressbar-portal {
  width: 100%;
  transition: width 0.4s ease 0s;
}

.grecaptcha-badge {
  visibility: hidden;
}
