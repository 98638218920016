@use 'variables';
@use 'colors';
@use 'typography';

.antd-overwrite {
  .ui-uploader {
    .ant-upload {
      p {
        margin: 0;
      }

      &-drag {
        background-color: colors.$tertiary-050 !important;
        border: 1px dashed colors.$tertiary-600 !important;
        border-radius: 12px !important;
        margin-bottom: variables.$margin-8;

        &-icon {

          & > svg {
            width: 24px;
            height: 25px;

            & > g {
              fill: colors.$tertiary-800 !important;
            }
          }
        }

        // drag hover
        &-hover {
          background-color: colors.$tertiary-100 !important;
          border-color: colors.$tertiary-800 !important;
        }

        // mouse hover
        &:hover {
          background-color: colors.$tertiary-100 !important;
          border-color: colors.$tertiary-800 !important;
        }
      }

      &-btn {
        padding: 0 !important;
      }

      &-drag-container {
        padding: variables.$padding-32;
        color: colors.$tertiary-800;
        margin-bottom: variables.$margin-8;
      }

      &-list-item {
        padding: variables.$padding-20 variables.$padding-28 !important;
        border: 1px solid colors.$grey-warm-300 !important;
        border-radius: 8px !important;

        &-info {
          color: colors.$grey-warm-600;

          @extend .h5;
        }

        &-file {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &-thumbnail {
          display: flex;
          width: 21px !important;
          height: 24px !important;

          & > svg {
            width: 21px;
            height: 24px;

            & > path {
              fill: colors.$tertiary-500-base;
            }
          }
        }

        &-card-actions {
          &-btn {
            & > svg {
              width: 18px;
              height: 18px;

              & > path {
                fill: colors.$secondary-500-base;
              }
            }
          }

          & > :nth-child(1) {
            margin-right: variables.$margin-8;
          }
        }

        &-progress {
          padding-left: 36px !important;
          bottom: 5px !important;
          width: calc(100% - 50px) !important;
        }
      }
    }

    .h4 {
      white-space: break-spaces;
    }
  }
}
