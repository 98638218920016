@use 'colors';
@use 'typography';
@use 'variables';
@use 'mixins';

.antd-overwrite {

  // Stepper icon styles

  .ant-steps-item-container > .ant-steps-item-icon .ant-steps-icon {
    text-transform: uppercase;
    font-family: var(--cera-pro), sans-serif;
    font-style: normal;
    font-size: 20px;
    font-weight: bold;
  }

  .ant-steps-item-finish, .ant-steps-item-wait {
    .ant-steps-item-icon > .ant-steps-icon {
      color: colors.$white;
    }
  }

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background-color: colors.$secondary-500-base;
    border-color: colors.$secondary-500-base;
  }

  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-icon {
    background-color: colors.$secondary-500-base;
    border-color: colors.$secondary-500-base;
  }

  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-icon {
    background-color: colors.$primary-200;
    border-color: colors.$primary-200;
  }

  .ant-steps-item-title {
    padding-right: 4px;
    text-transform: uppercase;
    font-family: var(--cera-pro), sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    @include mixins.mobile {
      font-size: 14px;
    }
    @include mixins.desktop-medium {
      font-size: 16px;
    }
  }

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: colors.$secondary-500-base;
  }

  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: colors.$primary-200;
  }

  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: colors.$secondary-500-base;

    &:after {
      background-color: colors.$secondary-500-base;
    }
  }

  .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
    padding-left: 4px;
  }

  .ant-steps {
    justify-content: space-between;
  }

  .ant-steps-item {
    display: flex;
    align-items: center;
    flex: 1 1 auto !important;

    .ant-steps-icon {
      top: -1px !important;
      vertical-align: middle;
    }

    .ant-steps-item-container {
      width: auto;
    }

    .ant-steps-item-icon {
      margin: 0 4px;
      box-sizing: content-box;
      border: 4px solid colors.$grey-violet-050 !important;
      border-radius: 50%;
      vertical-align: middle;
      @include mixins.desktop-medium {
        vertical-align: middle;
      }
    }

    .ant-steps-item-icon:after, .ant-steps-item-icon:before {
      position: absolute;
      top: 20px;
      z-index: -9999;
      display: block;
      width: 9999px;
      height: 1px;
      background: #a0a6bd;
      content: '';
    }

    .ant-steps-item-icon:after {
      left: 25%
    }

    .ant-steps-item-icon:before {
      right: 30%
    }

    &:last-child {
      flex: 0 0 auto !important;

      .ant-steps-item-icon:after {
        display: none
      }
    }

    &:first-child {
      .ant-steps-item-icon:before {
        display: none
      }
    }
  }

  .ant-steps-item-title::after {
    display: none;
  }

  .ant-steps-item-container {
    display: flex;
    flex-direction: column;
    background: inherit;
    align-items: center;
    @include mixins.desktop-medium {
      width: 100%;
      background: colors.$grey-violet-050;
      display: inherit;
      flex-direction: inherit;
      align-items: inherit;
    }
  }
}
