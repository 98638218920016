@use '../../../styles/colors';
@use '../../../styles/variables';

.formFields {
  border-bottom: 1px solid colors.$grey-violet-200;
  margin: 0 0 variables.$margin-24 0;
}

.actionButtons {
  display: flex;
  gap: 12px;
  margin-bottom: 0 !important
}

.descriptionField {
  margin-bottom: variables.$margin-28;
}
