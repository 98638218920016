@use 'colors';
@use 'variables';
@use 'typography';

.antd-overwrite {
  .ant-select-item {
    padding: variables.$padding-4 !important;
    @extend .main-body-text;
  }

  .ant-dropdown {
    color: colors.$grey-warm-600 !important;

    &-menu {
      padding: variables.$padding-4 0;

      &-item {
        padding: 0 variables.$padding-12;
      }
    }

    &-menu-item-active {
      background: colors.$grey-violet-200!important;
    }

    &-menu-item-selected {
      background: colors.$grey-violet-400;
      color: inherit;
    }
  }
}

.dropdown-overlay {
  position: fixed;
  background: colors.$white;
  border: 1px solid colors.$grey-violet-200;
  box-shadow: 0 0 16px -4px rgba(223, 224, 233, 0.6);
  border-radius: 8px;

  .ant-dropdown-menu-title-content > a {
    color: colors.$secondary-500-base !important;

    &:hover {
      color: colors.$secondary-600 !important;
      text-decoration: underline;
    }
  }
}
