@use 'colors';
@use 'typography';
@use 'variables';
@use 'mixins';

.antd-overwrite {
  .ant-drawer-body {
    padding: 0 !important;
    h5 {
      @extend .h5;
      @extend .uppercase;
      color: colors.$grey-warm-600 !important;
      margin: -#{variables.$margin-4} 0 variables.$margin-20 0;
    }
  }

  div[class*='ant-drawer-wrapper-body'] {
    position: relative;
  }

  div[class*='ant-drawer-body'] {
    overflow-x: hidden;
    padding: 12px !important;
  }

  div[class*='ant-drawer-header'] {
    position: absolute;
    z-index: 2;
    border: none;
    right: 0;
    top: 7px;
  }
}

.employee-submenu-container {
  height: 100%;
  border-right: 0;

  &.ant-menu {
    margin: 0;
    //padding: variables.$padding-12 !important;
    border-radius: 8px !important;
    background-color: colors.$white;
    border-inline-end: none !important;

    .ant-motion-collapse-leave {
      padding: 0 !important;
    }

    .ant-menu-submenu {
      border-radius: 4px;

      .ant-menu-sub {
        background-color: colors.$white;
        border-radius: 4px;
        padding: 0 variables.$padding-12 variables.$padding-4 variables.$padding-12;

        .ant-menu-item-only-child {
          border-radius: 4px;
        }
      }

      .ant-menu-item-only-child .ant-menu-title-content {
        @include mixins.body-text(14px, 18px);
      }

      &:hover {
        background: colors.$grey-violet-100;
      }

      .ant-menu-submenu-title {
        @include mixins.bottomBarMenuTitle(colors.$grey-warm-500);

        span {
          margin-inline-start: 0;
        }
      }

      .ant-menu-item {
        @include mixins.body-text(14px, 18px);

        padding: variables.$padding-12 variables.$padding-16 variables.$padding-12 variables.$padding-32 !important;
      }
    }

    .ant-menu-submenu-selected.ant-menu-submenu-open {
      padding-right: 2px;

      &:hover {
        background: colors.$grey-violet-300;
      }

      & > .ant-menu-sub {
        background-color: colors.$white !important;
      }

      .ant-menu-submenu-title {
        @include mixins.bottomBarMenuTitle(colors.$grey-warm-500);
        @include mixins.body-text(14px, 18px);
      }
    }

    .ant-menu-submenu-open {
      background-color: colors.$white !important;
    }

    .ant-menu-item:last-child {
      margin-bottom: 0 !important;
    }

    .ant-menu-item:first-child {
      margin-top: 0 !important;
    }

    .ant-menu-item {
      width: 100%;
      background-color: colors.$white;
      margin-bottom: variables.$margin-4;
      padding: variables.$padding-12 variables.$padding-16 !important;
      display: flex;
      border-radius: 4px;
      flex-direction: row;
      align-items: center;
      height: auto;
      color: colors.$grey-warm-500;

      .ant-menu-title-content {
        @include mixins.heading(14px, 18px);
      }

      &:after {
        content: none;
      }

      &:hover {
        background-color: colors.$grey-violet-200 !important;
      }

      .ant-menu-item-icon {
        margin-right: variables.$margin-8;
        width: 16px !important;
        height: 16px !important;

        & path {
          fill: colors.$grey-warm-500;
        }

        + span {
          margin-inline-start: 0;
        }
      }

      &.ant-menu-item-selected {
        background: colors.$grey-violet-200;
        color: colors.$secondary-500-base !important;

        @include mixins.heading(14px, 18px);
        .ant-menu-item-icon {
          & path {
            fill: colors.$secondary-500-base;
          }
        }
      }
    }
  }
}

.bottom-bar-container {
  @include mixins.desktop-large {
    display: none;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.ant-menu-horizontal {
    border-bottom: none;

    &::before, &::after {
      display: none;
    }

    .ant-menu-overflow-item {
      .ant-menu-title-content {
        @include mixins.heading(14px, 18px);
      }
    }

    .ant-menu-overflow-item-rest:last-of-type {
      display: none;
    }

    .ant-menu-item {
      flex: 1 1 20%;
      position: unset !important;
      overflow-y: unset !important;
      opacity: unset !important;
      pointer-events: unset !important;
      height: unset !important;

      & > div {
        margin: 0 !important;
        padding: variables.$padding-4;
      }

      padding: 0;
      border-bottom: none !important;

      &:hover {
        color: colors.$grey-violet-700;
        &::before, &::after {
          display: none;
        }
      }

      &:after {
        border: none;
      }

      &.ant-menu-item-selected {
        & > div {
          border: 1px solid colors.$secondary-500-base;
          padding: variables.$padding-4;
          width: 24px;
          height: 24px;
        }

        border-bottom: none;
        color: colors.$grey-warm-700;

        .ant-menu-item-icon {
          & path {
            fill: colors.$secondary-500-base;
          }
        }
        &::after {
          border: none;
        }
      }

      margin: 0;
      color: colors.$grey-warm-400;

      @include mixins.heading(14px, 18px);
      display: flex;
      flex-direction: column;
      align-items: center;

      .ant-menu-item-icon {
        margin-bottom: variables.$margin-4;
        height: 24px !important;
        width: 24px !important;
        flex-shrink: 0;
        margin-right: 0;

       + span {
          margin-inline-start: 0;
        }

        & path {
          fill: colors.$grey-warm-500;
          height: 24px !important;
          width: 24px !important;
        }
      }
    }
  }
}

