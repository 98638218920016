@use 'colors';

.antd-overwrite {
  .ant-checkbox-wrapper {
    color: colors.$grey-warm-600;

    &-checked {
      &:hover {
        //color: colors.$secondary-500-base;
        //.ant-checkbox-inner {
        //  background-color: colors.$secondary-500-base;
        //}
      }
    }

    .ant-checkbox {
      background-color: colors.$white;

      .ant-checkbox-inner {
        border-radius: 4px;
        border: 1px solid colors.$grey-warm-400;
      }

      &:hover {
        .ant-checkbox-inner {
          border: 1px solid colors.$secondary-500-base;
        }
      }

      &:focus, &:active {
        .ant-checkbox-inner {
          border: 1px solid colors.$secondary-500-base;
          box-shadow: 0 0 0 2px colors.$secondary-050;
        }
      }

      &.ant-checkbox-checked {
        .ant-checkbox-inner {
          background-color: colors.$secondary-500-base !important;
          border: 1px solid colors.$secondary-500-base !important;
        }

        &:after {
          border: 1px solid colors.$secondary-500-base;
          border-radius: 4px;
        }

        &.ant-checkbox-disabled {
          .ant-checkbox-inner {
            background-color: colors.$secondary-100 !important;
            border: 1px solid colors.$secondary-100 !important;
            &:after {
              border-color: colors.$white;
            }
          }
        }
      }

      &.ant-checkbox-disabled {
        .ant-checkbox-inner {
          background-color: colors.$grey-warm-100;
          border: 1px solid colors.$grey-warm-200;
          border-radius: 4px;
          box-shadow: none;
        }

        + span {
          color: colors.$grey-warm-300;
        }
      }
    }
  }
}

