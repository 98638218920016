@use '../../../../styles/colors';
@use '../../../../styles/variables';

.footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 5;
  height: variables.$footer-height;
  background-color: colors.$grey-violet-200;
  border-top: 1px solid colors.$grey-violet-300;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 variables.$padding-12;
}

.allRightsReserved {
  margin: 0;
}

.logoBasicColor {
  color: colors.$primary-500-base;
}

.logoSecondaryColor {
  color: colors.$secondary-500-base;
}

.startPlanBtn {
  background: transparent !important;
  border: none !important;
  padding: variables.$padding-8 !important;

  &:hover {
    background: colors.$secondary-050 !important;
  }
}
