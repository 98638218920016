@use '../../../styles/colors';
@use '../../../styles/mixins';

.success {
  @include mixins.info(colors.$success-500, colors.$success-050)
}

.error {
  @include mixins.info(colors.$danger-500, colors.$danger-100);
}

.info {
  @include mixins.info(colors.$info-500, colors.$info-050);
}

.warning {
  @include mixins.info(colors.$warning-500, colors.$warning-050);
}

.default {
  @include mixins.info(colors.$grey-violet-700, colors.$grey-violet-100);
}

.purple {
  @include mixins.info(colors.$sup-purple-600, colors.$sup-purple-050);
}

.defaultDarker {
  @include mixins.info(colors.$grey-warm-600, colors.$grey-warm-100);
}

.dashed {
  @include mixins.info(colors.$primary-200, colors.$white);
  border: 1px dashed colors.$primary-200;
}

.job {
  @include mixins.info(colors.$grey-violet-600, colors.$grey-violet-300);
}

.white {
  @include mixins.info(colors.$grey-warm-500, colors.$white);
  border: 1px solid colors.$primary-200;
}
